<!-- A设备管理-巡检管理-巡检计划 -->
<template>
    <div class="page_container height_100">
            <div class="box_head1">
                <span>点检计划</span>
                <div class="flex mg_left_50 fn_size14 align_center">
                <div class="">计划类型：</div>
                <el-select v-model="planTypeArValue" placeholder="请选择" class="divMargin width_150" clearable>
                    <el-option v-for="item in planTypeAr" :key="item.value" :label="item.description"
                        :value="item.value">
                    </el-option>
                </el-select>
                <div class="">计划时间：</div>
                <!-- <el-input v-model="input" class="width_150"></el-input> -->
                <el-date-picker v-model="planTimeValue" type="datetimerange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" class="mg_left_10" :picker-options="pickerOptions">
                </el-date-picker>
                <el-button type="primary" class="mg_left_10" size="medium"
                    @click="query">查询
                </el-button>            
            </div>
                </div>
        <div class="bgc_FFFFFF bgc_FFFFFF_height pdl30 pdr30">
            
            <div class="flex justify_between pdt_20 fn_size16">
                <div class="flex">
                    <el-button type="primary" icon="el-icon-circle-plus-outline"
                        class="width_140 bgc_03CABE buttonBorder" @click="gotoAddRepair"
                        style="background-color:#03CABE ;">添加计划</el-button>
                    <!-- <el-button type="primary" icon="el-icon-sold-out" class="width_140 bgc_FEA313 buttonBorder"
                        style="background-color: #FEA313;">执行计划
                    </el-button> -->
                </div>
                <!-- <el-button type="primary" class="width_110" style=" background: #009BFD;border: none;">导出
                </el-button> -->
            </div>
                <el-table ref="multipleTable" :data="tableData" style="width: 100%" highlight-current-row
                    class="mg_top_20" @current-change="handleSelectionChange" height="80%"
                    :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <!-- <el-table-column prop="ID" label="id" width=""></el-table-column> -->
                    <el-table-column prop="planID" label="计划编号" width="" show-overflow-tooltip></el-table-column>
                    <!-- <el-table-column prop="projectID" label="项目编号" width=""></el-table-column> -->
                    <el-table-column prop="planType_name" label="计划类型" width=""></el-table-column>
                    <el-table-column prop="planName" label="计划名称" width=""></el-table-column>
                    <el-table-column prop="planStatus_name" label="计划状态" width=""></el-table-column>
                    <!-- <el-table-column label="是否审批" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.isAuth}}</span>
                        </template>
                    </el-table-column> -->
                    <!-- <el-table-column prop="authSort" label="当前审核顺序" width=""></el-table-column>
                    <el-table-column prop="execLenM" label="执行时间长度" width=""></el-table-column>
                    <el-table-column prop="oprPerson" label="记录人" width=""></el-table-column> -->
                    <!-- <el-table-column prop="oprTime" label="记录时间" width="" :formatter="formatDate"></el-table-column> -->
                    <el-table-column prop="planTime" label="计划时间" width="180" :formatter="formatDate"></el-table-column>
                    <!-- <el-table-column prop="remindTime" label="提醒时间" width="" :formatter="formatDate"></el-table-column> -->
                    <!-- <el-table-column prop="assignEndTime" label="指定点检结单时间" width="" :formatter="formatDate"> -->
                    <!-- </el-table-column> -->
                    <el-table-column label="是否循环" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.isLoop?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="loopTime" label="循环周期" width=""></el-table-column>
                    <el-table-column prop="loopTimeUnit_name" label="循环周期单位" width=""></el-table-column>
                    <!-- <el-table-column prop="pushTimes" label="推送次数" width=""></el-table-column> -->
                    <!-- <el-table-column prop="execGroup" label="点检班组" width=""></el-table-column> -->
                    <!-- <el-table-column prop="execPerson" label="点检人" width=""></el-table-column> -->
                    <el-table-column prop="enabled" label="是否启用" width="">
                        <template slot-scope="scope">
                            <span>{{scope.row.enabled?'是':'否'}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="planLocScope" label="计划位置范围" width=""></el-table-column>
                    <!-- <el-table-column prop="remarks" label="备注" width=""></el-table-column> -->
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="gotoFaultDetails(scope.row.ID)">详细</span>
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="删除" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="editTableData(scope.row.ID)">修改</span>
                            </el-button>
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;"
                                    @click="delTableData(scope.row.ID)">删除</span>
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            <el-pagination layout="prev, pager, next" :total="pageQuantity*pageSize" :page-size="pageSize"
                :current-page.sync="currentPage" @current-change="handleCurrentChange"></el-pagination>
            <!-- 页码 -->
            <!-- <div class="flex justify_between align_start">
                <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->
            <!-- <div class="bgc_E2F0FE flex align_end mg_top_24 height_64">
                <div class="tab_box mg_left_30 " :class="tabIndex==0?'tab_box_active':''" @click="handleChange(0)">巡检记录
                </div>
            </div> -->
            <!-- <div class="mg_top_31 flex justify_start">
                <el-select v-model="value" placeholder="全部" class="mg_right_10">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
                <el-select v-model="value">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div> -->
            <!-- <div class="mg_top_22 ">
                <el-table ref="multipleTable" :data="tableDataDetail" tooltip-effect="dark" style="width: 100%"
                    class="mg_top_20" max-height="500" :default-sort="{prop: 'date', order: 'descending'}">
                    <el-table-column type="selection" width="55"></el-table-column>
                    <el-table-column type="index" label="序号" width="55"></el-table-column>
                    <el-table-column prop="date" label="出入库单号" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="date" label="日期" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="创建人" width=""></el-table-column>
                    <el-table-column prop="name" label="仓库" width=""></el-table-column>
                    <el-table-column prop="name" label="方向" width="" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="address" label="类型" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="name" label="入库"></el-table-column>
                    <el-table-column prop="name" label="出库"></el-table-column>
                    <el-table-column prop="name" label="库存"></el-table-column>
                    <el-table-column prop="name" label="单价"></el-table-column>
                    <el-table-column prop="name" label="金额"></el-table-column>
                    <el-table-column prop="name" label="经办人"></el-table-column>
                    <el-table-column prop="address" label="关联单号" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="address" label="位置码" show-overflow-tooltip></el-table-column>
                    <el-table-column label="操作"> -->
            <!-- <template slot-scope="scope">
                            <el-button type="text" class="color_009BFD pdt_0 pdb_0">
                                <span style="border-bottom: 1px solid #009BFD;">详细</span>
                            </el-button>
                        </template> -->
            <!-- </el-table-column>
                </el-table>
            </div> -->
            <!-- 页码 -->
            <!-- <div class="flex justify_between align_start">
                <div class="color_808080 fn_size14 mg_top_19">当前1-10 总记录：123,456</div>
                <el-pagination layout="prev, pager, next" :total="50"></el-pagination>
            </div> -->
        </div>
    </div>
</template>

<script>
    import {
        inspectionPlanDelete,
        inspectionPlanMultiAuthQueryLimit,

        inspectionPlanLoopUnitQuery,
        inspectionPlanTypeQuery
    } from "@/api/api_deviceManagement";
    export default {
        // components: {
        //   innerTopNav
        // },
        data() {
            return {
                tabIndex: 0,
                value: '',
                input: '',
                options: [{
                    value: '选项1',
                    label: '黄金糕'
                }, {
                    value: '选项2',
                    label: '双皮奶'
                }, {
                    value: '选项3',
                    label: '蚵仔煎'
                }, {
                    value: '选项4',
                    label: '龙须面'
                }, {
                    value: '选项5',
                    label: '北京烤鸭'
                }],
                tableData: [],
                tableDataDetail: [],
                multipleSelection: null,
                deleteNum: '',
                currentPage: 1,
                pageSize: 10,
                pageQuantity: 0,

                planLoopArValue: '',
                planLoopAr: [],
                planTypeArValue: '',
                planTypeAr: [],
                planTimeValue: null
            }
        },
        mounted() {
            // this.inspectionPlanLoopUnitQuery();
            this.inspectionPlanTypeQuery();
            this.inspectionPlanMultiAuthQueryLimit();
        },
        methods: {
            // async inspectionPlanLoopUnitQuery() {
            //     const res = await inspectionPlanLoopUnitQuery();
            //     this.planLoopAr = res.rows;
            // },
            async inspectionPlanTypeQuery() {
                const res = await inspectionPlanTypeQuery();
                this.planTypeAr = res.rows;
            },
            handleSelectionChange(val) {
                console.log(val)
                this.multipleSelection = val;
                // console.log(JSON.parse(val.planContList))
            },
            handleChange(id) {
                this.tabIndex = id;
            },
            //添加
            gotoAddRepair() {
                this.$router.push({
                    path: '/deviceManagement/patrolManagement/patrolPlan/addPatrolPlan',
                })
            },
            // 修改
            editTableData(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/patrolManagement/patrolPlan/modifyPatrolPlan',
                })
            },
            //详细
            gotoFaultDetails(id) {
                localStorage.setItem('oderId', id);
                this.$router.push({
                    path: '/deviceManagement/patrolManagement/patrolPlan/patrolPlanDetails',
                })
            },
            //删除
            delTableData(id) {
                this.deleteNum = id;
                this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //删除方法
                    this.currentPage = this.delData.delData(this.tableData.length, this.pageQuantity,this.currentPage)
                    this.inspectionPlanDelete();
                }).catch(() => {
                    // this.$message({
                    //     type: 'info',
                    //     message: '已取消删除'
                    // });
                });
            },
            //删除方法：调用了删除接口，实现点检标准的删除
            async inspectionPlanDelete() {
                var param = {
                    ID: this.deleteNum
                }
                const res = await inspectionPlanDelete(param);
                if (res && res.data && res.data.result) {
                    //调用分页筛选方法，刷新删除之后的页面
                    this.$message({
                        message: res.message,
                        type: 'success'
                    });
                    this.inspectionPlanMultiAuthQueryLimit();
                } else {
                    this.$message.error(res.msssage);
                }
            },
            query() {
                this.currentPage = 1;
                this.inspectionPlanMultiAuthQueryLimit();
            },
            //点检计划筛选-分页数据
            async inspectionPlanMultiAuthQueryLimit() {
                var param = {
                    pageSize: this.pageSize,
                    pageNumber: this.currentPage,
                }
                // if (this.planLoopArValue != null && this.planLoopArValue.toString() != "") {
                //     param.loopTimeUnit = this.planLoopArValue;
                // }
                if (this.planTypeArValue != null && this.planTypeArValue.toString() != "") {
                    param.planType = this.planTypeArValue
                }
                if (this.planTimeValue != null) {
                    param.planStartTime = this.planTimeValue[0];
                    param.planEndTime = this.planTimeValue[1];
                }
                const selectRes = await inspectionPlanMultiAuthQueryLimit(param);
                this.pageQuantity = selectRes.pageQuantity;
                this.tableData = selectRes.array;
                this.multipleSelection = this.tableData[0]
            },
            handleCurrentChange(val) {
                //this.PageNumber = val;
                this.currentPage = val;
                this.inspectionPlanMultiAuthQueryLimit(); //查询table数据的方法
            },
            formatDate(row, column) {
                // 获取单元格数据
                let data = row[column.property]
                if (data == undefined) {
                    return ''
                };
                return this.tools.formatDate(data, 'yyyy-MM-dd hh:mm:ss');
            },
        }
    }
</script>

<style scoped>
    .tab_box {
        width: 176px;
        height: 50px;
        line-height: 50px;
        color: #1A1A1A;

    }

    .tab_box_active {
        color: #009BFD;
        border-top: 3px solid #009BFD;
        background-color: #FFFFFF;
        border-left: 1px solid #E6E6E6;
        border-right: 1px solid #E6E6E6;
    }

    .divMargin {
        margin-right: 20px;
    }

    .buttonBorder {
        border: none;
    }

    .table_box {
        height: 539px
    }
</style>
<style>
    /* .btn_hover:hover {
        background-color: #03CABE;
        opacity: 0.5;
        color: #FFFFFF;
    } */

    /* .el-button:focus,
    .el-button:hover {
    } */
</style>